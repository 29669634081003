var loadChecks = {
  initLoad: false,
  homeLoad: false,
  socialsLoad: false,
  contactLoad: false,
  lookbookLoad: false,
  popupLoad: false
}

export default loadChecks
