export const terms = `Terms of Use<br /><br />
<br /><br />PLEASE NOTE: THE SECTION OF THESE TERMS OF USE ENTITLED
"DISPUTE RESOLUTION" CONTAINS AN ARBITRATION CLAUSE THAT REQUIRES
DISPUTES TO BE ARBITRATED ON AN INDIVIDUAL BASIS, AND PROHIBITS CLASS
ACTION CLAIMS. IT AFFECTS HOW DISPUTES BETWEEN YOU AND A-ANTI ARE
RESOLVED. BY ACCEPTING THESE TERMS OF USE, YOU AGREE TO BE BOUND BY
THIS ARBITRATION PROVISION. PLEASE READ IT CAREFULLY. PLEASE NOTE THAT
YOUR USE OF AND ACCESS TO OUR SERVICES (DEFINED BELOW) ARE SUBJECT TO
THE FOLLOWING TERMS; IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU
MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER. <br /><br />
Effective date: May 1, 2019
<br /><br />
Please read on to learn the rules and restrictions that govern your
use of our website(s), products, services and applications (the
"Services"). If you have any questions, comments, or concerns
regarding these terms or the Services, please contact us at
admin@anti-eshop.com, or at 510 5th Avenue, 4th Floor, New York, NY
10036.
<br /><br />
If your habitual residence is in the United States, these Terms of Use
("Terms") are a binding contract between you and A-ANTI, LLC
("A-ANTI", "ANTI", "we" and "us"). You must agree to and accept all of
the Terms, or you don't have the right to use the Services. Your using
the Services in any way means that you agree to all of these Terms,
and these Terms will remain in effect while you use the Services.
These Terms include the provisions in this document, as well as those
in the Privacy Policy. [www.anti-eshop.com > Privacy].
<br /><br />
Will these Terms ever change?<br /><br />
We are constantly trying to improve our Services, so these Terms may
need to change along with the Services. We reserve the right to change
the Terms at any time, but if we do, we will bring it to your
attention by placing a notice on the Services, by sending you an
email, and/or by some other means. If you don't agree with the new
Terms, you are free to reject them; unfortunately, that means you will
no longer be able to use the Services. If you use the Services in any
way after a change to the Terms is effective, that means you agree to
all of the changes.
<br /><br />
Except for changes by us as described here, no other amendment or
modification of these Terms will be effective unless in writing and
signed by both you and us.
<br /><br />What about my privacy?<br /><br />
A-ANTI takes the privacy of its users very seriously. For the current
A-ANTI Privacy Policy, please visit our Privacy Policy [www.anti-eshop.com >
Privacy].
<br /><br />
The Children's Online Privacy Protection Act ("COPPA") requires that
online service providers obtain parental consent before they knowingly
collect personally identifiable information online from children who
are under 13. We do not knowingly collect or solicit personally
identifiable information from children under 13; if you are a child
under 13, please do not attempt to register for the Services or send
any personal information about yourself to us. Parents and legal
guardians may not agree to these Terms or register for the Services on
behalf of any children under the age of 13. If we learn we have
collected personal information from a child under 13, we will delete
that information as quickly as possible. If you believe that a child
under 13 may have provided us personal information, please contact us
at admin@anti-eshop.com.
<br /><br />What are the basics of using the Services?<br /><br />
You may be required to sign up for an account, and select a password
and user name ("A-ANTI User ID"). You promise to provide us with
accurate, complete, and updated registration information about
yourself. You may not select as your A-ANTI User ID a name that you
don't have the right to use, or another person's name with the intent
to impersonate that person. You may not transfer your account to
anyone else without our prior written permission. You represent and
warrant that you are an individual of legal age to form a binding
contract (or if not, you've received your parent's or guardian's
permission to use the Services and gotten your parent or guardian to
agree to these Terms on your behalf). You will only use the Services
for your own internal, personal, use, and not on behalf of or for the
benefit of any third party, and only in a manner that complies with
all laws that apply to you. If your use of the Services is prohibited
by any applicable laws, then you aren't authorized to use the
Services. We can't and won't be responsible for your using the
Services in a way that breaks the law. You will not share your account
or password with anyone, and you must protect the security of your
account and your password. You're responsible for any activity
associated with your account. You may have multiple accounts on A-ANTI
but A-ANTI strictly forbids the use of multiple accounts for the same
sole user or the use of one account for multiple users for the purpose
of earning more rewards. A-ANTI will not honor rewards earned from
such accounts. By using the Services, you will automatically be deemed
to have opted in to receive e-mail communication from us. When you
sign in for the first time, your preferences will be set to
automatically publish all social interactions to other social
networks. However, you will be able to edit these preferences at any
time under "Settings."
<br /><br />
Your use of the Services is subject to the following additional
restrictions:<br /><br />
You represent, warrant, and agree that you will not contribute any
Content or User Submission (each of those terms is defined below) or
otherwise use the Services or interact with the Services in a manner
that: Infringes or violates the intellectual property rights or any
other rights of anyone else (including A-ANTI); Violates any law or
regulation, including any applicable export control laws; Is harmful,
fraudulent, deceptive, threatening, harassing, defamatory, obscene, or
otherwise objectionable; Jeopardizes the security of your A-ANTI
account or anyone else's (such as allowing someone else to log in to
the Services as you); Attempts, in any manner, to obtain the password,
account, or other security information from any other user; Violates
the security of any computer network, or cracks any passwords or
security encryption codes; Runs Maillist, Listserv, any form of
auto-responder or "spam" on the Services, or any processes that run or
are activated while you are not logged into the Services, or that
otherwise interfere with the proper working of the Services (including
by placing an unreasonable load on the Services' infrastructure);
"Crawls," "scrapes," or "spiders" any page, data, or portion of or
relating to the Services or Content (through use of manual or
automated means); Copies or stores any significant portion of the
Content; Decompiles, reverse engineers, or otherwise attempts to
obtain the source code or underlying ideas or information of or
relating to the Services; or  Upload, transmit, distribute, store,
create, or otherwise sell or offer for sale any Prohibited Items. A
violation of any of the foregoing is grounds for termination of your
right to use or access the Services.
<br /><br />What are my rights in the Services?<br /><br />
The materials displayed or performed or available on or through the
Services, including, but not limited to, text, graphics, data,
articles, photos, images, illustrations, User Submissions, and so
forth (all of the foregoing, the "Content") are protected by copyright
and/or other intellectual property laws. You promise to abide by all
copyright notices, trademark rules, information, and restrictions
contained in any Content you access through the Services, and you
won't use, copy, reproduce, modify, translate, publish, broadcast,
transmit, distribute, perform, upload, display, license, sell or
otherwise exploit for any purpose any Content not owned by you, (i)
without the prior consent of the owner of that Content or (ii) in a
way that violates someone else's (including A-ANTI's) rights. You
understand that A-ANTI owns the Services. You won't modify, publish,
transmit, participate in the transfer or sale of, reproduce (except as
expressly provided in this Section), create derivative works based on,
or otherwise exploit any of the Services. The Services may allow you
to copy or download certain Content; please remember that just because
this functionality exists, doesn't mean that all the restrictions
above don't apply - they do!
<br /><br />Do I have to grant any licenses to A-ANTI or to other
users?<br /><br />
Anything you post, upload, share, store, or otherwise provide through
the Services is your "User Submission." Some User Submissions are
viewable by other users. In order to display your User Submissions on
the Services, and to allow other users to enjoy them (where
applicable), you grant us certain rights in those User Submissions.
Please note that all of the following licenses are subject to our
Privacy Policy [www.anti-eshop.com > Privacy] to the
extent they relate to User Submissions that are also your
personally-identifiable information. For all User Submissions, you
hereby grant A-ANTI a license to translate, modify (for technical
purposes, for example making sure your content is viewable on a
smartphone, a tablet, as well as a computer) and reproduce and
otherwise act with respect to such User Submissions, in each case to
enable us to operate the Services, as described in more detail below.
This is a license only - your ownership in User Submissions is not
affected. If you store a User Submission in your own personal A-ANTI
account, in a manner that is not viewable by any other user except you
(a "Personal User Submission"), you grant A-ANTI the license above, as
well as a license to display, perform, and distribute your Personal
User Submission for the sole purpose of making that Personal User
Submission accessible to you and providing the Services necessary to
do so.
<br /><br />
If you share a User Submission only in a manner that only certain
specified users can view (for example, a private message to one or
more other users)(a "Limited Audience User Submission"), then you
grant A-ANTI the licenses above, as well as a license to display,
perform, and distribute your Limited Audience User Submission for the
sole purpose of making that Limited Audience User Submission
accessible to such other specified users, and providing the Services
necessary to do so. Also, you grant such other specified users a
license to access that Limited Audience User Submission, and to use
and exercise all rights in it, as permitted by the functionality of
the Services.
<br /><br />
If you share a User Submission publicly on the Services and/or in a
manner that more than just you or certain specified users can view, or
if you provide us (in a direct email or otherwise) with any feedback,
suggestions, improvements, enhancements, and/or feature requests
relating to the Services (each of the foregoing, a "Public User
Submission"), then you grant A-ANTI the licenses above, as well as a
license to display, perform, and distribute your Public User
Submission for the purpose of making that Public User Submission
accessible to all A-ANTI users and providing the Services necessary to
do so, as well as all other rights necessary to use and exercise all
rights in that Public User Submission in connection with the Services
and/or otherwise in connection with A-ANTI's business, provided that
A-ANTI will try to notify you if it uses your Public User Submission
for any reason other than displaying it on the Services. Also, you
grant all other users of the Services a license to access that Public
User Submission, and to use and exercise all rights in it, as
permitted by the functionality of the Services.
<br /><br />
You agree that the licenses you grant are royalty-free, perpetual,
sublicenseable, irrevocable, and worldwide.
<br /><br />
Finally, you understand and agree that A-ANTI, in performing the
required technical steps to provide the Services to our users
(including you), may need to make changes to your User Submissions to
conform and adapt those User Submissions to the technical requirements
of connection networks, devices, services, or media, and the foregoing
licenses include the rights to do so.
<br /><br />What if I see something on the Services that infringes my
copyright?<br /><br />
You may have heard of the Digital Millennium Copyright Act (the
"DMCA"), as it relates to online service providers, like A-ANTI, being
asked to remove material that allegedly violates someone's copyright.
We respect others' intellectual property rights, and we reserve the
right to delete or disable Content alleged to be infringing, and to
terminate the accounts of repeat alleged infringers; to review our
complete Copyright Dispute Policy and learn how to report potentially
infringing content, see here. To learn more about the DMCA, see here.
<br /><br />Who is responsible for what I see and do on the
Services?<br /><br />
Any information or content publicly posted or privately transmitted
through the Services is the sole responsibility of the person from
whom such content originated, and you access all such information and
content at your own risk, and we aren't liable for any errors or
omissions in that information or content or for any damages or loss
you might suffer in connection with it. We cannot control and have no
duty to take any action regarding how you may interpret and use the
Content or what actions you may take as a result of having been
exposed to the Content, and you hereby release us from all liability
for you having acquired or not acquired Content through the Services.
We can't guarantee the identity of any users with whom you interact in
using the Services and are not responsible for which users gain access
to the Services. You are responsible for all Content you contribute,
in any manner, to the Services, and you represent and warrant you have
all rights necessary to do so, in the manner in which you contribute
it. You will keep all your registration information accurate and
current. You are responsible for all your activity in connection with
the Services. The Services may contain links or connections to third
party websites or services that are not owned or controlled by A-ANTI.
When you access third party websites or use third party services, you
accept that there are risks in doing so, and that A-ANTI is not
responsible for such risks. We encourage you to be aware when you
leave the Services and to read the terms and conditions and privacy
policy of each third party website or service that you visit or
utilize. A-ANTI has no control over, and assumes no responsibility
for, the content, accuracy, privacy policies, or practices of or
opinions expressed in any third party websites or by any third party
that you interact with through the Services. In addition, A-ANTI will
not and cannot monitor, verify, censor or edit the content of any
third party site or service. By using the Services, you release and
hold us harmless from any and all liability arising from your use of
any third party website or service. Your interactions with
organizations and/or individuals found on or through the Services,
including payment and delivery of goods or services, and any other
terms, conditions, warranties or representations associated with such
dealings, are solely between you and such organizations and/or
individuals. You should make whatever investigation you feel necessary
or appropriate before proceeding with any online or offline
transaction with any of these third parties. You agree that A-ANTI
shall not be responsible or liable for any loss or damage of any sort
incurred as the result of any such dealings. In particular, and
without limiting the foregoing, you agree that A-ANTI does not
facilitate (and bears no responsibility or liability for) any
transaction (or payment or refund therefore) arranged through A-ANTI
Local. If there is a dispute between participants on this site, or
between users and any third party, you agree that A-ANTI is under no
obligation to become involved. In the event that you have a dispute
with one or more other users, you release A-ANTI, its officers,
employees, agents, and successors from claims, demands, and damages of
every kind or nature, known or unknown, suspected or unsuspected,
disclosed or undisclosed, arising out of or in any way related to such
disputes and/or our Services.
<br /><br />
YOU SHALL AND HEREBY DO WAIVE CALIFORNIA CIVIL CODE SECTION 1542,
WHICH SAYS: "A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE
CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE
TIME OF EXECUTING THE RELEASE, WHICH, IF KNOWN BY HIM OR HER MUST HAVE
MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR."
<br /><br />Will A-ANTI ever change the Services?<br /><br />
We're always trying to improve the Services, so they may change over
time. We may suspend or discontinue any part of the Services, or we
may introduce new features or impose limits on certain features or
restrict access to parts or all of the Services. We'll try to give you
notice when we make a material change to the Services that would
adversely affect you, but this isn't always practical. Similarly, we
reserve the right to remove any Content from the Services at any time,
for any reason (including, but not limited to, if someone alleges you
contributed that Content in violation of these Terms), in our sole
discretion, and without notice.
<br /><br />Do the Services cost anything?<br /><br />
It is currently free to access and browse A-ANTI.com or any other
Services. However, you may choose to purchase products through the
Services, and you alone will be responsible for paying the purchase
price of any products you buy, in addition to any taxes and shipping
costs. A-ANTI may limit or cancel quantities of products purchased,
and it reserves the right to refuse any order. In the event A-ANTI
needs to make a change to an order, it will attempt to notify you by
contacting the email address, billing address, and/or phone number
provided at the time the order was made. Prices are subject to change
at any time. Due to separate and applicable tax jurisdictions, your
purchases may be subject to specific sales, custom or value-added
taxes, and the shipping time and associated cost may increase
accordingly. A-ANTI reserves the right to require payment of fees for
certain or all Services, in addition to the fees charged for the
purchase of products. You shall pay all such fees, as described on the
website in connection with such Services selected by you. A-ANTI
reserves the right to change its price list and to institute new
charges at any time, upon notice to you, which may be sent by email or
posted on the Website. Your use of the Services following such
notification constitutes your acceptance of any new or increased
charges.
<br /><br />Prices<br /><br />
Prices are set by the third-party merchants offering these items for
sale, and not by A-ANTI. The merchants also provide all information in
the item listings, including any strike-through prices. A-ANTI does
not represent, warrant, or guarantee that the merchant actually
offered or sold the item at the strike-through price.
<br /><br />What if I need to return a purchase?<br /><br />
We want you to be completely satisfied with your purchase on the
Services. If for any reason you are unhappy, please contact A-ANTI
support at admin@anti-eshop.com.
<br /><br />
However, A-ANTI does NOT return merchandise, NOR does A-ANTI refunds
money for the merchandise purchased on A-ANTI's website. All sales are
final. <br /><br />
NO returns, refunds or exchanges are permitted.
<br /><br />
A-ANTI cannot refund, reimburse, cover, or otherwise be responsible
for any fees not paid to A-ANTI. This includes any customs taxes or
VAT.
<br /><br />What if I want to receive A-ANTI mobile text alerts or
opt-out?<br /><br />
If you would like to enroll to receive mobile texts and alerts about
A-ANTI products and services, you may sign up to do so on the checkout
page for purchasing a product through A-ANTI by providing your consent
to receive recurring autodialed marketing texts from or on behalf of
us at the mobile number you've provided to opt-in. You understand that
consent is not a condition of purchase. Message and data rates may
apply. If you would like to be removed from the A-ANTI text list, you
can follow the instructions provided in those messages or otherwise
reply STOP to any A-ANTI alert. If you have any questions, you may
reply HELP to any A-ANTI alert or contact customer care at
admin@anti-eshop.com.
<br /><br />What if I want to stop using the Services?<br /><br />
You're free to do that at any time, by following the steps at
Help/FAQ.   Please refer to our Privacy Policy, as well as the
licenses above, to understand how we treat information you provide to
us after you have stopped using our Services. A-ANTI is also free to
terminate (or suspend access to) your use of the Services or your
account, for any reason in our discretion, including your breach of
these Terms. A-ANTI has the sole right to decide whether you are in
violation of any of the restrictions set forth in these Terms. Account
termination may result in destruction of any Content associated with
your account, so keep that in mind before you decide to terminate your
account. Provisions that, by their nature, should survive termination
of these Terms shall survive termination. By way of example, all of
the following will survive termination: any obligation you have to pay
us or indemnify us, any limitations on our liability, any terms
regarding ownership or intellectual property rights, and terms
regarding disputes between us.
<br /><br />I use the A-ANTI App via the Apple App Store - should I
know anything about that?<br /><br />
These Terms apply to your use of all the Services, including the
applications available via the Apple, Inc. ("Apple") App Store
(including, without limitation, for iPhone, iPad, iTouch, and iWatch)
(each an "Application" and, collectively, the "Applications"), but the
following additional terms also apply to each Application: Both you
and A-ANTI acknowledge that the Terms are concluded between you and
A-ANTI only, and not with Apple, and that Apple is not responsible for
the Application or the Content; The Application is licensed to you on
a limited, non-exclusive, non-transferrable, non-sublicensable basis,
solely to be used in connection with the Services for your private,
personal, non-commercial use, subject to all the terms and conditions
of these Terms as they are applicable to the Services; You will only
use the Application in connection with an Apple device that you own or
control; You acknowledge and agree that Apple has no obligation
whatsoever to furnish any maintenance and support services with
respect to the Application; In the event of any failure of the
Application to conform to any applicable warranty, including those
implied by law, you may notify Apple of such failure; upon
notification, Apple's sole warranty obligation to you will be to
refund to you the purchase price, if any, of the Application; You
acknowledge and agree that A-ANTI, and not Apple, is responsible for
addressing any claims you or any third party may have in relation to
the Application; You acknowledge and agree that, in the event of any
third party claim that the Application or your possession and use of
the Application infringes that third party's intellectual property
rights, A-ANTI, and not Apple, will be responsible for the
investigation, defense, settlement and discharge of any such
infringement claim; You represent and warrant that you are not located
in a country subject to a U.S. Government embargo, or that has been
designated by the U.S. Government as a "terrorist supporting" country,
and that you are not listed on any U.S. Government list of prohibited
or restricted parties; Both you and A-ANTI acknowledge and agree that,
in your use of the Application, you will comply with any applicable
third party terms of agreement which may affect or be affected by such
use; and Both you and A-ANTI acknowledge and agree that Apple and
Apple's subsidiaries are third party beneficiaries of these Terms, and
that upon your acceptance of these Terms, Apple will have the right
(and will be deemed to have accepted the right) to enforce these Terms
against you as the third party beneficiary hereof.
<br /><br />What else do I need to know?<br /><br />
Warranty Disclaimer<br /><br />
Neither A-ANTI nor its licensors or suppliers makes any
representations or warranties concerning any content contained in or
accessed through the Services, and we will not be responsible or
liable for the accuracy, copyright compliance, legality, or decency of
material contained in or accessed through the Services. We (and our
licensors and suppliers) make no representations or warranties
regarding suggestions or recommendations of services or products
offered or purchased through the Services. Products and services
purchased or offered (whether or not following such recommendations
and suggestions) through the Services are provided "AS IS" and without
any warranty of any kind from A-ANTI or others (unless, with respect
to such others only, provided expressly and unambiguously in writing
by a designated third party for a specific product). THE SERVICES AND
CONTENT ARE PROVIDED BY A-ANTI(AND ITS LICENSORS AND SUPPLIERS) ON AN
"AS-IS" BASIS, WITHOUT WARRANTIES OR ANY KIND, EITHER EXPRESS OR
IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF
MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT,
OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. SOME
STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS,
SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
<br /><br />Limitation of Liability<br /><br />
TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO
CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT
LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL
A-ANTI (OR ITS LICENSORS OR SUPPLIERS) BE LIABLE TO YOU OR TO ANY
OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, OR
CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS,
LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER
FAILURE OR MALFUNCTION, OR (B) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS
OF THE GREATER OF (I) $100 OR (II) THE AMOUNTS PAID BY YOU TO A-ANTI
IN CONNECTION WITH THE SERVICES IN THE TWELVE (12) MONTH PERIOD
PRECEDING THIS APPLICABLE CLAIM, OR (III) ANY MATTER BEYOND OUR
REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR
LIMITATION OF CERTAIN DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS
MAY NOT APPLY TO YOU.
<br /><br />Indemnity<br /><br />
To the fullest extent allowed by applicable law, You agree to
indemnify and hold A-ANTI, its affiliates, officers, agents,
employees, and partners harmless from and against any and all claims,
liabilities, damages (actual and consequential), losses and expenses
(including attorneys' fees) arising from or in any way related to any
third party claims relating to (a) your use of the Services (including
any actions taken by a third party using your account), and (b) your
violation of these Terms. In the event of such a claim, suit, or
action ("Claim"), we will attempt to provide notice of the Claim to
the contact information we have for your account (provided that
failure to deliver such notice shall not eliminate or reduce your
indemnification obligations hereunder).
<br /><br />Assignment<br /><br />
You may not assign, delegate or transfer these Terms or your rights or
obligations hereunder, or your Services account, in any way (by
operation of law or otherwise) without A-ANTI's prior written consent.
We may transfer, assign, or delegate these Terms and our rights and
obligations without consent.
<br /><br />Choice Of Law & Jurisdiction.<br /><br />
These Terms and your use of the Services will be interpreted in
accordance with the law of the State of Delaware and the United States
of America, without regard to their conflict-of-law provisions. You
and A-ANTI agree to submit to the personal jurisdiction of a state
court located in Wilmington, Delaware, or a United States District
court, District of Delaware, (collectively, the "Delaware Courts") for
any actions which the parties retain the right to seek injunctive or
other equitable relief in a court of competent jurisdiction to prevent
the actual or threatened infringement, misappropriation, or violation
of a party's copyrights, trademarks, trade secrets, patents, or other
intellectual property rights, as set forth in the Dispute Resolution
provision below.
<br /><br />Miscellaneous<br /><br />
You will be responsible for paying, withholding, filing, and reporting
all taxes, duties, and other governmental assessments associated with
your activity in connection with the Services, provided that the
A-ANTI may, in its sole discretion, do any of the foregoing on your
behalf or for itself as it sees fit. The failure of either you or us
to exercise, in any way, any right herein shall not be deemed a waiver
of any further rights hereunder. If any provision of these Terms is
found to be unenforceable or invalid, that provision will be limited
or eliminated, to the minimum extent necessary, so that these Terms
shall otherwise remain in full force and effect and enforceable. You
and A-ANTI agree that these Terms are the complete and exclusive
statement of the mutual understanding between you and A-ANTI, and that
it supersedes and cancels all previous written and oral agreements,
communications and other understandings relating to the subject matter
of these Terms. You hereby acknowledge and agree that you are not an
employee, agent, partner, or joint venture of A-ANTI, and you do not
have any authority of any kind to bind A-ANTI in any respect
whatsoever. Except as expressly set forth in the section above
regarding the Apple Applications, you and A-ANTI agree there are no
third party beneficiaries intended under these Terms.
<br /><br />Dispute Resolution<br /><br />
Mandatory, Bilateral Arbitration and Waiver of Class Actions. Please
read this carefully. It affects your rights. YOU AND A-ANTI AGREE THAT
ANY DISPUTE, CONTROVERSY, OR CLAIM ARISING OUT OF, OR RELATING TO YOUR
USE OF A-ANTI, TO ANY PRODUCTS OR SERVICES SOLD OR DISTRIBUTED BY OR
THROUGH A-ANTI, TO THIS AGREEMENT, OR TO THE CONTENT, AND/OR USER
SUBMISSION (PUBLIC, PERSONAL AND/OR LIMITED AUDIENCE) ON A-ANTI SHALL
BE RESOLVED ONLY BY FINAL AND BINDING, BILATERAL ARBITRATION, except
that (1) you may assert claims in small claims court if your claims
qualify; and (2) this agreement to arbitrate does not include your or
A-ANTI's right to seek injunctive or other equitable relief in state
or federal court in San Francisco to prevent the actual or threatened
infringement, misappropriation or violation of a party's copyrights,
trademarks, trade secrets, patents, or other intellectual property
rights. The Federal Arbitration Act, 9 U.S.C. § 1, et seq., and
federal arbitration law apply to this agreement and govern all
questions as to whether a dispute is subject to arbitration. There is
no judge or jury in arbitration, and court review of an arbitration
award is limited. An arbitrator, however, can award on individual
basis the same damages and relief as a court (including injunctive and
declaratory relief or statutory damages), and must follow the terms of
these Terms of Use. "Disputes" shall include, but are not limited to,
any claims or controversies between you and A-ANTI against each other
related in any way to or arising out of in any way from the Service,
the Content, User Submission (Public, Personal, and/or Limited
Audience), including but not limited to sales, returns, refunds,
cancellations, defects, policies, privacy, advertising, or any
communications between you and A-ANTI, even if the claim arises after
you or A-ANTI has terminated Service or a user account. Disputes also
include, but are not limited to, claims that: (a) you bring against
our employees, agents, affiliates, or other representatives; or (b)
that A-ANTI brings against you. Disputes also include, but are not
limited to, (i) claims in any way related to or arising out of any
aspect of the relationship between you and A-ANTI, whether based in
contract, tort, statute, fraud, misrepresentation, advertising claims,
or any other legal theory; (ii) claims that arose before these Terms
or out of a prior set of Terms with A-ANTI; (iii) claims that are
subject to on-going litigation where you are not a party or a class
member; and/or (iv) claims that arise after the termination of these
Terms.
<br /><br />Dispute Notice<br /><br />
Before initiating an arbitration, you and A-ANTI each agree to first
provide the other a written notice ("Notice of Dispute"), which shall
contain: (a) a written description of the problem and relevant
documents and supporting information; and (b) a statement of the
specific relief sought. A Notice of Dispute should be sent to: 510 5th
Avenue, 4th Floor, New York, NY 10036, or emailed at
admin@anti-eshop.com. A-ANTI will provide a Notice of Dispute to you
via the email address associated with your A-ANTI User ID. You and
A-ANTI agree to make attempts to resolve the Dispute prior to
commencing an arbitration and not to commence an arbitration
proceeding until this 45-day post-notice resolution period expires. If
an agreement cannot be reached within forty-five (45) days of receipt
of the Notice of Dispute, you or A-ANTI may commence an arbitration
proceeding.
<br /><br />Arbitration Process and Procedure<br /><br />
Unless you and A-ANTI agree otherwise in writing, arbitration shall
(1) be administered by the Judicial Arbitration and Mediation
Services, Inc. ("JAMS"), pursuant to the JAMS Streamlined Arbitration
Rules & Procedures then in effect (the "JAMS Rules") and as modified
by this agreement to arbitrate, including the rules regarding filing,
administration, discovery, and arbitrator fees; (2) be conducted by a
single, neutral arbitrator; and (3) take place in the county where you
reside. To the extent that this agreement to arbitrate conflicts with
the JAMS Policy on Consumer Arbitrations Pursuant to Pre-Dispute
Clauses Minimum Standards for Procedural Fairness (the "Minimum
Standards"), the Minimum Standards in that regard will apply.
<br /><br />We each agree that any dispute resolution proceedings will
be conducted only on an individual basis and not in a class or
representative action. Further, unless both you and A-ANTI expressly
agree otherwise, the arbitrator may not consolidate more than one
person's claims. If this prohibition of class, representative, or
consolidated arbitration is found to be unenforceable, then the
entirety of this arbitration provision shall be null and void. If, for
any reason, a claim proceeds in court rather than in arbitration, we
each waive our right to a jury trial. If, for any reason, a claim
proceeds in court rather than in arbitration, we each waive our right
to a jury trial. <br /><br />YOU THEREFORE UNDERSTAND AND AGREE THAT
BY ENTERING INTO THIS AGREEMENT, YOU AND A-ANTI ARE EACH WAIVING THE
RIGHT TO A TRIAL BY JURY AND THE RIGHT TO PARTICIPATE IN A CLASS
ACTION FOR ANY CLAIMS COVERED BY THIS AGREEMENT. <br /><br />The
arbitrator may award declaratory or injunctive relief only in favor of
the individual party seeking relief and only to the extent necessary
to provide relief warranted by that party's individual claim.
<br /><br />The JAMS Rules are available on its website
at https://www.jamsadr.com/rules-streamlined-arbitration/.
Notwithstanding any JAMS Rules to the contrary or any other provision
in the arbitration rules chosen, by agreement, to govern, you and
A-ANTI each agree that all issues regarding the Dispute are delegated
to the arbitrator to decide, except that only a court (and not the
arbitrator) shall decide any disagreements regarding the scope and
enforceability of this agreement to arbitrate.
<br /><br />Hearing<br /><br />
If your claim does not exceed $5,000, you and A-ANTI agree to waive an
oral hearing by the arbitrator and the arbitration will be conducted
solely on the basis of documents you and A-ANTI submit to the
arbitrator, unless you request a hearing or the arbitrator determines
that a hearing is necessary. To the extent an oral hearing is
requested by you or A-ANTI, or deemed necessary by the arbitrator, you
and A-ANTI agree that the hearing will be conducted telephonically or
videographically.
<br /><br />Arbitrator's Decision<br /><br />
An arbitrator's award will be a written statement of the disposition
of each claim and will also provide a concise written statement of the
essential findings and conclusions which form the basis of the award.
The arbitrator's decision and award is final and binding, with some
limited court review under the FAA, and judgment on the award may be
entered in any court of competent jurisdiction.
<br /><br />Fees<br /><br />
It is each parties' responsibility to pay any JAMS filing, case
management/administrative, and arbitrator fees as set forth in the
JAMS Rules. If your claim for damages does not exceed $5,000, A-ANTI
will pay all such fees unless the arbitrator finds that either the
substance of your Dispute or the relief sought was frivolous or was
brought for an improper purpose (as measured by the standards set
forth in the Federal Rule of Civil Procedure 11(b)).
<br /><br />Small Claims & Government Actions<br /><br />
As an alternative to arbitration, you or A-ANTI may resolve Disputes
in a small claims court that has jurisdiction over your claim. These
Terms and this arbitration agreement do not prevent you from bringing
your Dispute to the attention of any federal, state, or local
government agency. Such agencies can, if the law allows, seek relief
against A-ANTI on your behalf.`

export const privacy = `A-ANTI LLC Privacy Policy
<br />
<br />
This Privacy Policy applies to the shopping site www.anti-eshop.com,
and app operated by A-ANTI LLC ("Services"). This Privacy Policy uses
the terms "our" "us" and "we" to refer to A-ANTI LLC and "you" or
"user(s)" to refer to individuals described by the information that
A-ANTI LLC collects and uses, such as customers and merchants. Please
read this document carefully to learn more about how we collect, use,
share and protect information, including information that may describe
you personally. This Privacy Policy does not apply to the practices of
companies we don't own or control, or people that we don't manage. For
example, it does not apply to the use of purchaser shipping
information by a merchant. Any capitalized terms we use in this
Privacy Policy without defining them have the definitions given to
them in the Terms of Use which govern Your use of the Services.
<br />
<br />
We operate a website, such as www.anti-eshop.com, that allow customers
to purchase items listed and sold on the website. We collect and use
purchaser information, including for payment methods and shipping
address, to support purchasers' shopping experience.
<br />
<br />
By using or accessing the Services, you acknowledge that we will
collect, use, and share your information as described in this Privacy
Policy.
<br />
<br />
You have choices about whether to provide us with information and how
we use that information. You may choose not to provide us with
information, but your choice(s) may prevent you from using the
Services or limit your use of certain features. For example, if you do
not provide us with a payment method, you may not be able to make
purchases.
<br />
<br />
See Section 7 for more information about your choices.
<br />
<br />
We're constantly trying to improve our Services, so we may need to
update this Privacy Policy. We may update this Privacy Policy to
reflect changes in the law, the Services, our company or advances in
technology. We will alert you to a change by placing a notice on the
Services or by sending an email. Our use of the information we collect
is subject to the Privacy Policy in effect at the time such
information is used.
<br />
<br />
1. Information We Collect & Use
<br />
<br />
Some of the information we collect is provided by you, some is
collected automatically (typically from devices) and some may be
collected from other sources.
<br />
<br />
A. Information You Provide
<br />
<br />
When you use the Services you often provide information to us, such as
when you create an account on our website, make or fulfill a purchase,
leave a review, or request customer support. Examples of the
information you may provide are: Name, Email, Address, Payment Method
or Financial Account Information, Shipping Address, Phone Number,
Social network account credentials
<br />
<br />
B. Information We Collect Automatically
<br />
<br />
When you use the Services, such as when you visit our site, and
install or use our app, we automatically collect information about how
you use the Services and the devices you use to access the Services.
Examples of the information we collect automatically are: IP Address,
Location information - we and our service providers may automatically
collect general location information from your computer or mobile
device. We will ask your permission before collecting your precise GPS
location information, Social network account and profile data (when
you use social login), Unique Device Identifiers, including MAC
Address, Ad IDs, and device attributes, such as operating system and
browser type, Usage Data, such as: web log data, referring and exit
pages and URLs, platform type, number of clicks, domain names, landing
pages, pages and content viewed and the order of those pages, the
amount of time spent on particular pages, the date and time you used
our Services, the frequency of your use of our Services, error logs,
and other similar information.
<br />
<br />
C. Analytics, Advertising & Technical Data
<br />
<br />
Analytics - We use Google Analytics or other providers to understand
better how individuals and their devices interact with the Services.
Learn more about How Google uses data when you visit a partner site or
app. Cookies and Related Technologies. We may use cookies, which are
text files containing small amounts of information that are downloaded
on your device, or related technologies, such as web beacons, local
shared objects and tracking pixels to store or collect information
("Cookies").
<br />
<br />
Cookies can help us learn about your online activity including on
other sites or services.
<br />
<br />
Ads for Products and Services. We may use services, like Facebook, to
serve tailored ads to you about the A-ANTI LLC services, our company
or products we are developing. We may also support or allow the
delivery of advertising for the products and services of other
companies. We allow ad networks and other participants in the online
advertising sector ("Advertisers") to use and access their own Cookies
on your computer or other device(s) you use to access our Services.
They may also access and use unique device identifiers, such as IDFA.
For example:
<br />
<br />
We may deliver a file to you through the Services (known as a "web
beacon") from an ad network. Web beacons allow ad networks to provide
anonymized, aggregated auditing, research and reporting for us and for
advertisers. Web beacons also enable ad networks to serve targeted
advertisements to you when you visit other websites. Because your web
browser must request these advertisements and web beacons from the ad
network's servers, these companies can view, edit, or set their own
cookies, just as if you had requested a web page from their site.
<br />
<br />
We do not have access to Cookies placed or read by Advertisers, and
this Privacy Policy does not govern the use of those cookies and
related technologies. See the Choice information in Section 7 for more
information. Third-Party Content, Links, or Plug-Ins.
<br />
<br />
The Services may have links to third-party websites or apps, which may
have privacy policies that differ from our own. We are not responsible
for the practices of such sites.
<br />
<br />
The Services may also offer you the ability to interact with social
plugins from social media sites, which may allow us and/or the social
media site to receive data from or about you. In some cases, we may
know that you clicked on a social plugin, such as a Twitter Follow
button, or receive other information from the social media sites.
Similarly, if you have previously provided personal information to a
third-party operating a plug-in on the Services, then such third-party
may recognize you on the Services. Your use of social network plugins
is subject to each social media site's privacy policy, which may be
different from ours, so please read these policies carefully to
understand their policies and your options. As with linked sites, we
have no control over the information that is collected, stored, or
used by social network plugins, and are not responsible for the
practices of such sites.
<br />
<br />
D. Other Sources
<br />
<br />
We may gather information, including demographic and statistical
information from third parties, such as business partners, marketers,
researchers, analysts. We may attribute this information to you based
on your assignment to certain statistical groups. We may use this
information to supplement the other information that we collect in
order to derive your possible interests and to provide more relevant
experiences for you within the Services and improve our products,
analytics, and advertising.
<br />
<br />
2. How We Use Information & Lawful Bases
<br />
<br />
In general, we collect, use and store ("process") your information to
provide the Services, to fix and improve the Services, to develop new
services, and to market our companies and their products and services.
Some specific examples of how we use information are: Collect payment
Communicate with you - using email, sms, push notifications or
platform messaging (like Whatsapp) - about your account or activity or
to market product listings, features, or events. Test changes in the
Services and develop new features Analyze use of the Services and
personalize content, including ads and prices Fix problems you may
have with the Services or a particular purchase, including answering
support questions and resolving disputes Prevent, detect, investigate
and respond to fraud, unauthorized access/use of the Services,
breaches of terms and policies, or other wrongful behavior Comply with
any procedures, laws, and regulations which apply to us, including
those that set retention periods
<br />
<br />
Lawful Bases
<br />
<br />
For purposes of European data protection law, we normally process
personal data where: we need to perform the contract we are about to
enter into or have entered into with you, such as when we process a
payment it is in our legitimate interests (or those of a third party)
and your data protection interests and fundamental rights and freedoms
do not override those interests, such as when we secure the Services,
engage in fraud prevention, and direct marketing we need to comply
with a legal or regulatory obligation we have your consent to do so.
We do not generally rely on consent as a lawful basis for processing
your personal data, but you have the right to withdraw consent to
marketing at any time by contacting us
<br />
<br />
Other Uses
<br />
<br />
We may combine the information we collect ("aggregate") or remove
pieces of information ("de-identify") to limit or prevent
identification of any particular user or device, which can help
support our research and marketing efforts. This Privacy Policy does
not apply to our use of such aggregated or de-identified information.
<br />
<br />
3. Location of Data Processing - United States and elsewhere
<br />
<br />
Information that we collect and use is normally processed in the
United States. By using the Services you: Acknowledge that your
information will be processed as described in this Privacy Policy; and
Consent to having your information transferred to our affiliates and
facilities in the United States or elsewhere or to facilities of third
parties with whom we share information as described in this Privacy
Policy.
<br />
<br />
4. Retention of Personal Data/Information
<br />
<br />
We keep your information for the time period required to complete the
purposes for which it is processed or satisfy legal retention
requirements. The length of time for which we retain information
depends on the purposes for which we collected and use it or the
requirements of applicable laws.
<br />
<br />
5. Information Sharing
<br />
<br />We do not rent, sell or share information except as described in
this Privacy Policy. We may share your information as described in
this section:
<br />
<br />
Agents/Service Providers
<br />
<br />
We employ other companies and people to perform tasks on our behalf
and need to share information with them so they can perform tasks and
services. For example, we may use a payment processing company to
receive and process your payment when you make a purchase using the
Services or we may use a cloud services provider to store data.
Affiliates
<br />
<br />
We may share your information with other companies under common
ownership or control with A-ANTI LLC, as the case may be. These
companies may use your information as described in this Privacy
Policy.
<br />
<br />
Merchants & Users
<br />
<br />
Information about users (purchasers) is shared in certain contexts.
For example, when users complete a purchase using the Services, we
normally share information needed to fulfill the purchase, such as
shipping address and phone number, with a shipping company that is
used to ship the purchased goods.
<br />
<br />
Advertisers
<br />
<br />
We allow advertisers, including merchants ("Advertisers"), to choose
the types of users who will see their advertisements or promotional
offers based on demographic information about our users. We might
allow Advertisers to display their ads to users with similar usage
patterns or similar geographic locations. For example, if you are
located in Austin, TX we may show you ads or offers for businesses in
Austin, TX. If an advertiser asks us to show an ad to a certain
audience or audience segment and you respond to that ad, the
advertiser may conclude that you fit the description of the audience
they were trying to reach. With Other Users: Any information you may
disclose on the Services, in blogs, on message boards, in chat rooms,
or on other public areas on the Services or other third-party websites
or apps that the Services may link to, becomes public information
accessible to other users and visitors. Please exercise caution when
disclosing personal information in these public areas. Certain user
profile information, including your name, photo, location, purchase
history, items on your wishlist, reviews you have written (including
any photos you upload with such reviews), and the list of people you
follow or that follow you, may be displayed to other users to
facilitate user interaction within the Services or address your
request for our services. Please remember that any content you upload
to your public user profile, along with any personal information or
content that you voluntarily disclose online in a manner other users
can view (on discussion boards, in messages and chat areas, etc.)
becomes publicly available, and can be collected and used by anyone.
Your user name may also be displayed to other users if and when you
send messages or comments or upload images or videos through the
Services and other users can contact you through messages and
comments. Additionally, if you sign into the Services through a third
party social networking site or service, such as Facebook, your list
of "friends" from that site or service may be automatically imported
to the Services, and such "friends, " if they are also registered
users of the Services, may be able to access certain non-public
information you have entered in your Services user profile. Again, we
do not control the policies and practices of any other third party
site or service.
<br />
<br />
Business Transfers
<br />
<br />
We may choose to buy or sell assets and may share or transfer user
information (including personal information) in connection with the
evaluation of and entry into such transactions. Also, if we (or our
assets) are acquired, or if we go out of business, enter bankruptcy,
or go through some other change of control, user information could be
one of the assets transferred to or acquired by a third party.
<br />
<br />
Legal Requirements, Protection of Wish and Others
<br />
<br />
We reserve the right to access, read, preserve, and disclose any
information that we in good faith believe is necessary to comply with
law or court order; enforce or apply our Terms of Use and other
agreements; or protect the rights, property, or safety of A-ANTI LLC,
our employees, our users, or others.
<br />
<br />
Consent
<br />
<br />
We may share your information in other ways if you have asked us to do
so or have given consent.
<br />
<br />
6. Security
<br />
<br />
Accounts are protected by a password for your privacy and security. If
you access your account via a third party site or service, such as
Facebook, you may have additional or different sign-on protections via
that third party site or service. We recommend that you choose an
appropriate password, safeguard your password and limit access to the
devices on which you access your account.
<br />
<br />
We use reasonable organizational and technical measures intended to
protect the privacy of your account and personal information we use or
store, but the Internet and our Services are not 100% secure. We
cannot guarantee complete privacy or security for the information that
you provide or that we collect.
<br />
<br />
7. Choices & Rights
<br />
<br />
Access, Review, Correct Through your account "Settings" you are able
to access, edit or delete information you've provided to us,
including: name and password email address physical address location
phone number gender billing information user profile information,
including images and videos you have uploaded to the Services The
information you can view, update, and delete may change as the
Services change. If you have any questions about viewing or updating
information we have on file about you, please contact us at
admin@anti-eshop.com.
<br />
<br />
Marketing Communication Opt-out
<br />
<br />
To opt-out of marketing communications you can use any of the
following methods. Electronic Promotional Offers. If you do not want
to receive emails from us regarding special promotions or offers, you
may (1) follow the unsubscribe options at the bottom of each email; or
(2) contact us at admin@anti-eshop.com and provide your email address
along with the name of the newsletter from which you would like to
unsubscribe. Mobile Promotional Offers: When you provide us with your
mobile number for marketing purposes, we may send you certain
marketing alerts via text message. Standard data and message rates
will apply. If you no longer wish to receive mobile alerts from us,
you can follow the instructions provided in those messages or
otherwise reply STOP to any alert we send. Push Notifications: When
you use the App, you may receive push notifications. If you prefer not
to receive push notifications, you may adjust your settings on your
mobile device to control whether you want to receive these alerts.
Settings - You can opt out of certain communications from us by
changing your preferences under the "Settings" option of the
applicable service. Location Information If you do not want us to see
or access your device location, you can turn off location sharing on
your device, change your device privacy settings, or decline to share
location on your browser.
<br />
<br />
Cookies, Analytics & Ads
<br />
<br />
You may be able to opt-out of certain web beacon tracking and other
Cookie activity by adjusting the settings on your browser, including
Do Not Track settings. To opt-out of Google Analytics you can install
the Google Analytics Opt-out Browser Add-on. To exercise choices for
tailored advertising, please visit the following sites. If you
opt-out, you may still receive advertising content, but it will not be
tailored to you. Network Advertising Initiative (NAI) - You may use
the NAI opt out tool here, which will allow you to opt out of seeing
personalized ads from NAI member companies. Digital Advertising
Alliance (DAA) - You may opt out of receiving personalized ads from
certain companies that perform ad targeting services, using the DAA
website here. European Interactive Advertising Digital Alliance (EDAA)
– You can learn more about online advertising and opt out at the Your
Online Choices website. For your mobile devices, please read your
operating system's instructions for complete instructions. iOS 7 or
Higher: Go to your Settings > Select Privacy > Select Advertising >
Enable the "Limit Ad Tracking" setting For Android devices with OS 2.2
or higher and Google Play Services version 4.0 or higher: Open your
Google Settings app > Ads > Enable "Opt out of interest-based
advertising"
<br />
<br />
Deactivate Account
<br />
<br />
You may request deactivation of your account by clicking on
"Deactivate Account" in your "Settings" and following the steps
provided.
<br />
<br />
Rights
<br />
<br />
If our processing of your personal data is covered by the USA data
protection law, you typically have certain rights, including: You can
access and review information associated with your account at any time
through the "Settings" page or tab. You also can request the following
information: how we collect and use your information and why; the
categories of personal data involved; the categories of recipients of
your personal data; how we received your personal data; and how long
we use or store your personal data or the manner in which we determine
relevant retention periods. You also have a right to correct your
personal data. In certain situations, you can ask that we stop using
your information or erase it or export it. You may also have a right
to object to additional processing of your personal data. Where we
rely on your consent to process your personal data, you have the right
to not give your consent or (if you have provided consent) to withdraw
consent at any time. Withdrawing consent will not affect the
lawfulness of processing prior to the withdrawal. At any time, you can
request that we stop using your information for direct marketing
purposes. You have a right to raise questions or complaints with us as
described below or with your local data protection authority at any
time.
<br />
<br />
Complaints
<br />
<br />
We are committed to resolving valid complaints about your privacy and
our collection or use of your personal data or information. For
questions or complaints regarding our data use practices or Privacy
Policy, please contact us using the email address listed in Section 10
of this Privacy Policy.
<br />
<br />
8. Children's Privacy
<br />
<br />
We do not knowingly collect or solicit "personal information" (as
defined by the U.S. Children's Online Privacy Protection Act) from
anyone under the age of 13. If you are under 13, please do not attempt
to register for the Services or send any personal information about
yourself to us. If we learn that we have collected personal
information from a child under age 13, we will delete that
information. If you believe that a child under 13 may have provided us
personal information, please contact us at admin@anti-eshop.com.
<br />
<br />
For residents of the USA, where processing of personal data is based
on consent, we will not knowingly engage in that processing for users
under the age of consent established by applicable member or data
protection law. If we learn that we are engaged in that processing
with such users, we will halt such processing and will take reasonable
measures to promptly remove applicable information from our records.
<br />
<br />
If you are 13 or older, but have not reached your State's or country's
age of majority (i.e. able to enter a contract), you should use the
Services with permission from your parent(s) or guardian(s).
<br />
<br />
9. Do Not Track & California Shine the Light
<br />
<br />
Do Not Track Policy
<br />
<br />
Your browser may offer you a "Do Not Track" option, which allows you
to signal to operators of websites and web applications and services
(including behavioral advertising services) that you do not want your
online activities tracked over time and across different websites. Our
Services do not support Do Not Track requests at this time, which
means that we collect information about your online activity both
while you are using the Services and after you leave our Services.
<br />
<br />
California Residents
<br />
<br />
If you are a California resident, you may ask for a list of third
parties that have received your information for direct marketing
purposes during the previous calendar year. This list also contains
the types of information shared. We provide this list at no cost. To
make such a request, contact us at admin@anti-eshop.com.
<br />
<br />
10. Contact Information
<br />
<br />
If you have any questions or concerns regarding this Privacy Policy or
A-ANTI LLC's use of your data, please send a detailed message to
admin@anti-eshop.com. You may also write to us at: A-ANTI LLC, 510 5th
Avenue, 4th Floor, New York, NY 10036, or admin@anti-eshop.com.
Updated: May 4, 2019`

export const taxes = `A-ANTI LLC Tax Policy<br /><br />
A-ANTI LLC uses this page to keep customers up to date with changes in
how sales and use taxes are reported and withheld in various
locations.
<br /><br />
Last Updated: May 4, 2019<br /><br />
United States<br /><br />
Oklahoma<br /><br />
A-ANTI LLC includes Oklahoma's 4.5% sales/use tax in the price shown
to customers.
<br /><br />Connecticut<br /><br />
A-ANTI LLC includes Connecticut's 6.35% sales/use tax in the price
shown to customers.
<br /><br />Iowa<br /><br />
A-ANTI LLC includes Iowa's 7% sales/use tax in the price shown to
customers.
<br /><br />South Dakota<br /><br />
After March 1, 2019 - A-ANTI LLC includes South Dakota's 4.5%
sales/use tax in the price shown to customers.
<br /><br />Pennsylvania<br /><br />
Customers in Pennsylvania may owe sales or use tax on certain
purchases made through the A-ANTI LLC website. Pennsylvania requires
e-commerce purchasers to file a use tax return in Pennsylvania if tax
is due in connection with the purchase and delivery of an item -
reporting all taxable purchases for which no sales or use tax was
collected by A-ANTI LLC and paying sales or use tax on those
purchases. This notice is provided to Pennsylvania customers pursuant
to the requirements of Pa. Stat. Ann. § 7213.2. You may want to speak
with a tax professional to understand your obligations, if any. A-ANTI
LLC shows the 6% sales/use tax as a separate line item at checkout.
<br /><br />Washington<br /><br />
Customers in Washington may owe sales or use tax on certain purchases
made through the A-ANTI LLC website. Washington requires e-commerce
purchasers to file a use tax return in Washington if tax is due in
connection with the purchase and delivery, reporting all taxable
purchases for which no sales or use tax was collected by A-ANTI LLC
and paying sales or use tax on those purchases. This notice is
provided to Washington customers pursuant to the requirements of Wash.
Rev. Code § 82.13.020. You may want to speak with a tax professional
to understand your obligations, if any. A-ANTI LLC includes
Washington's 7% sales tax in the price shown to customers
<br /><br />Australia<br /><br />
A-ANTI LLC shows Australia's GST in the price shown to customers.
<br /><br />Europe<br /><br />
<br /><br />Sweden<br /><br />
A-ANTI LLC includes Sweden VAT in the price(s) shown to customers that
are delivered to customers in Sweden by PostNord, as the case may be.`